/**
 * Required for fonts and default styles of Orion Web Components
 */
@import url('@orion/orion-design-system/dist/orion-web-components/orion-web-components.css');

/*
 * Prevent scroll virtualization in MUI DataGrid so Draggable/Droppable components can interact in the same plane
 */
.MuiDataGrid-virtualScroller, .MuiDataGrid-main {
    overflow: unset !important;
    min-height: 240px;
}

/**
 * Fixes issue with MUI DataGrid container spilling out
 */
.MuiDataGrid-virtualScrollerRenderZone {
    position: static !important;
}

/**
 * Style column headers
 */
.MuiDataGrid-columnHeaders {
    background-color: #f1f1f0;
}

.MuiDataGrid-overlayWrapper {
    width: auto !important;
}


#documentSearchPage div.MuiTablePagination-root  div.MuiTablePagination-toolbar> :not(:last-child) {
    visibility: hidden;
}