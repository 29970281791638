@import '../../../assets/sass/app.scss';
.nodatafound {
    display: flex;
    justify-content: center;
    align-items: center;
}
.tablecontents {
    display: flex;
    flex-direction: column;
    width: 100%;
    //max-height: 400px;
    overflow-y: auto;
    ul {
        padding-inline-start: 0;
        li {
            list-style: none;
            
        }
        li:hover {
            cursor: pointer;
        }
    }
    // .manual-toggle {
    //     opacity: 0;
    // }
    // .manual-toggle:checked ~ .manual-body {
    //     opacity: 1;
    //     height: auto;
    // }
    // .manual-body {
    //     opacity: 0;
    //     height: 0;
    //     transition-duration: .5s;
    // }
    
    .manual-title {
        margin: 0 30px 0 30px;
        padding: 0 0 10px 0;
        // border-top: 1px solid $grey;
    }
    .manual-title-page {
        display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #0C2340;
    color: #FFFFFF;
    font-size: 20px;
    height: 60px;
    padding-left: 20px;
    }
    .manual-sec-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px 30px 0 60px;
        padding: 17px 0 10px 20px;
        border-bottom: 1px solid $grey;
        font-size: 20px;
    }
    .manual-sub-sec-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px 30px 0 90px;
        padding: 17px 0 10px 20px;
        border-bottom: 1px solid $grey;
        font-size: 20px;
    }
    .doc-info {
        margin: 10px 30px 0 90px;
        padding: 17px 0 10px 20px;
        border-bottom: 1px solid $grey;
        font-size: 16px;
        color: $united-purple;
        align-items: center;
    }
    .doc-first-item {
        margin: 10px 30px 0 60px !important;
    }
    .doc-actions {
        display: flex;
        justify-content: flex-end;
        width: 100px;
        div {
            padding-right: 10px;
        }
    }
    .hide-section {
        display: none;
    }
}
.contents-spinner-container {
    display: flex;
    align-items: center;
    height: 200px;
}
.right-arrow {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    transform: rotate(-45deg);
    margin-right: 15px;
    margin-top: 0px;
    color: $united-purple;
}
.right-arrow:hover {
    cursor: pointer;
}
.arrow {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    margin-right: 15px;
    margin-top: -10px;
    color: $united-purple;
}
.arrow:hover {
    cursor: pointer;
}

@media screen and (max-width: 1020px) {
    .manual-sub-sec-title {
        .title {
            width: 450px
        }
    }
}
@media screen and (max-width: 940px) {
    .manual-sub-sec-title {
        .title {
            width: 350px;
            .doc-title {
                width: 200px
            }
        }
    }
}
@media screen and (max-width: 800px) {
    .manual-sub-sec-title {
        .title {
            width: 260px;
        }
    }
    .tablecontents {
        .doc-info {
            font-size: 14px;
            .doc-title {
                width: 260px;
            }
        }
    }
}
@media screen and (max-width: 660px) {
    .manual-sub-sec-title {
        .title {
            width: 200px;
        }
    }
    .tablecontents {
        .doc-info {
            font-size: 12px;
            .doc-title {
                width: 200px;
            }
        }
    }
}
@media screen and (max-width: 595px) {
    .manual-sub-sec-title {
        .title {
            width: 180px;
        }
    }
    .tablecontents {
        .doc-info {
            .doc-title {
                width: 180px;
            }
        }
    }
}
@media screen and (max-width: 565px) {
    .manual-sub-sec-title {
        .title {
            width: 150px;
        }
    }
    .tablecontents {
        .doc-info {
            .doc-title {
                width: 150px;
            }
        }
    }
}
@media screen and (max-width: 525px) {
    .manual-sub-sec-title {
        .title {
            width: 120px;
        }
    }
    .tablecontents {
        .doc-info {
            .doc-title {
                width: 120px;
            }
        }
    }
}