@import '../assets/sass/app.scss';
.rectangle {
    width: 100%;
    height: 22px;
    background: white;
    margin-top: -18px;
    position: sticky;
    top:58px;
    z-index: 50;
  }
.container {
    //height: 80vh;
    margin-top: -10px;
    .actions {
        position: sticky;
        top: 58px;
        background-color: white;
        z-index: 50;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        padding-top: 10px;
        padding-left:20px;
        .dfarsMessage{
            margin-left: 50px;
            font-weight: 500;
            color: red;
            font-size: 14px;
        }
        .textual {
            margin-right: 20px;
            .info {
                font-size: 16px;
                .rev-section {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    svg {
                        fill: $united-purple;
                    }
                }
            } 
        }
        .buttons {
            display: flex;
            flex-direction: row;
            .button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 90px;
                height: 70px;
                background-color: $white;
                color: $united-purple;
                font-size: 9px;
                border: 2px solid $united-purple;
                border-radius: 5px;
                .icon {
                    margin-bottom: 3px;
                    svg {
                        fill: $united-purple;
                        path {
                            fill: $united-purple;
                        }
                    }
                }
                .contents {
                    padding-left: 10px;
                }
                .pins {
                    padding-left: 5px;
                }
            }
            .active {
                background-color: $united-purple;
                color: $white;
                .icon {
                    svg {
                        fill: $white;
                        path {
                            fill: $white;
                        }
                    }
                }
            }
        }
        .alert{
            width: 0px;
            height: 0px;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            border-bottom: 24px solid #F46335;
            //margin-top: 24px;
            margin-left: 12px;
            margin-right: 20px;
            flex-direction:column;
            border-radius: 3px;
            color: $white;
            font-size: 15px;
            font-weight: 600;  
            display:flex;  
            align-items: center;  
            justify-content: end; 
            cursor: pointer;
        }
        .itemEX
        {
            margin-top: 4px;
        }
        .alertcontainer
        {
            display: flex;
            flex-direction: column;
            width: 36%;
        }
        .alertbox
        {
            height: 72px;
            width: 100%;
            overflow-y: auto;
            background-color: $white;
            //border: 1px solid $light-border;
            border-radius: 5px;
            //z-index: 100;
            display: flex;
            flex-direction: row;
            align-items:center;
            justify-content: left;
            font-size: 18px;
            font-weight: 600;
            color: #F46335;
            padding-left: 0 !important;
            border-bottom: 1px solid #F46335;
            border-right: 1px solid #F46335;
            border-left: 1px solid #F46335;
            border-top: 1px solid #F46335;
        }
        .alertarrow
        {
            width: 36%;
            color: #F46335;
            height: 24px;
            width: 24px;
            margin-left: auto;
            margin-right: 20px;
        }
        .alertcontentbox
        {
            position: fixed;
            max-height: 350px;
            margin-top: 72px;
            //height: 72px;
            overflow-y: auto;
            background-color: $white;
            border-radius: 5px;
            z-index: 100;
            display: flex;
            flex-direction: row;
            align-items:center;
            justify-content:flex-start;
            font-size: 18px;
            font-weight: 400;
            color: black;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 17px;
            padding-right: 21px;
            border-bottom: 1px solid #F46335;
            border-right: 1px solid #F46335;
            border-left: 1px solid #F46335;
            border-top: 1px solid #F46335;
        }
        .rightbox
        {
            display: flex;
            flex-direction: row;
            margin-left: auto;
            justify-content: space-between;
            align-items: center;
            font-weight: 400;
            .pingicon
            {
                width: 110px;
                height: 64px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                font-size: 10px;
                color: $united-purple;

            }
            .comptag
            {
                width: 175px;
                height: 19px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 12px;
                margin-right: 40px;
                font-size: 14px;
                font-weight: 400;
            }
        }
        .bottombox{
            position: fixed;
            display: flex;
            justify-content: center;
            width: 100%;
            top: 57px;
            left: 0px;
            .searchlegend
            {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height: 49px;
                width: 50%;
                padding-left: 15px;
                padding-right: 15px;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 18px;
                font-weight: 400;
                color: $united-purple;
                border-radius: 6px;
                border-bottom: 1px solid $united-blue;
                border-right: 1px solid $united-blue;
                border-left: 1px solid $united-blue;
                border-top: 1px solid $united-blue;
                background-color: $white;
                .underline
                {
                    color: $united-purple;
                    border-bottom: 1px solid $united-purple;
                    cursor: pointer;
                }
                .innerbox
                {
                    display: flex;
                    flex-direction: column;    
                    //align-items: center;                
                    .innertitle
                    {
                        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                        font-size: 12px;
                        font-weight: 600;
                        padding-right: 11.5px;
                    }
                    
                }
                .innerbox-center
                {
                    align-items: center;
                }
                .innerbox-wide
                {
                    width: 30%;
                }
                .modal-close {
                    cursor: pointer;
                    width: 13px;
                    height: 13px;
                    margin-top: -30px;
                    margin-right: -15px;
                }
                .modal-close:after {
                    content: '';
                    height: 13px;
                    border-left: 2px solid $united-purple;
                    position: absolute;
                    transform: rotate(45deg);
                }
                .modal-close:before {
                    content: '';
                    height: 13px;
                    border-left: 2px solid $united-purple;
                    position: absolute;
                    transform: rotate(-45deg);
                }
                .navigation
                {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    .right-arrow-nomargin
                    {
                        margin-right: -18px;
                    }
                    .arrow-disable
                    {
                        cursor: none;
                        color: #949494;
                    }
                }
            }
        }
    }
    .firefox-warning {
        color: $failure;
        margin-bottom: 10px;
    }
    .infobar{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        .titlebanner
        {
            height: 51px;
            margin-top: 12px;
            //margin-left: 14px;
            //margin-left: auto;
            padding-left: 14px;
            background-color: #666666;
            border: 1px, solid, #979797;
            color: #CCCCCC;

            display: flex;
            flex-direction: row;
            align-items: center;
            .title
            {
                display: flex;
                flex-direction: row;
            }
            .subjecttext
            {
                color: #FFFFFF;
                padding-left: 5px;
            }
            .titlebannericon
            {
                width: 10%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding-top: 12px;
                align-items: center;
                margin-left: auto;
                margin-right: 10.75px;
                .bannericon{
                    display: flex;
                    flex-direction: row;
                    margin-right: 19.81px;
                    margin-bottom: 9.5px;
                    align-items: center;
                }
            }
            
        }
        .helpbar
        {
            display: flex;
                flex-direction: row;
            height: 61px;
            width: 100%;
            //padding-left: 14px;
            background-color: #D8D8D8;
            justify-content: center;
            align-items: center;
            .helpbox
            {
                display: flex;
                flex-direction: row;
                height: 48px;
                width: 99%;
                border: 1px solid #0C2340;
                border-radius: 6px;
                background-color: #FFFFFF;
                justify-content: flex-start;
                align-items: center;
                .itemI{
                    //width: 100%;
                    width: 24px;
                    height: 24px;
                    margin-left: 13px;
                    margin-right: 20px;
                    //margin-top: 5px;
                    flex-direction: row;
                    //border-color: $light-border;
                    border-radius: 50px;
                    background-color: $black;
                    color: $white;
                    font-size: 15px;
                    font-weight: 600;  
                    display: inline-flex;  
                    align-items: center;  
                    justify-content: center; 
                }
            }
        }
    }
    .pdf-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 35px;
        color: $united-purple;
        div {
            margin-top: 200px;
        }
    }
    .pdf-viewer{
        display: flex;
        //border: '1px solid rgba(0, 0, 0, 0.3)';
        width: 100%;
        height:60%;
        //z-Index: -100;
    }
    .contents-toggle {
        opacity: 0;
    }
    .contents-toggle:checked ~ .slideout{
        opacity: 1;
        width: 60vw;
    }
    .slideout {
        position:fixed;
        top:162px;
        left: 156px;
        z-index: 100;
        //top: 160px;
        width: 0;
        //left: 108px;
        max-width: 908px;
        height: auto;
        opacity: 0;
        box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
        transition-duration: .25s;
    }
    .slideout-view {
        opacity: 1;
        width: 60vw;
    }
    a {
        color: $united-purple;
        font-weight: 800;
    }
    @media screen and (max-width: 840px) {
        .slideout {
            top: 234px;
        }
    }
    @media screen and (max-width: 440px) {
        .slideout {
            top: 274px;
        }
        .slideout-view {
            width: 80vw;
        }
    }
    
}

.pdfcontainer {
    height: 735px;
    width: 100%;
}

.wrapperbreadcrumb {
    display: flex;
    margin-bottom: 10px;
}
.mainbreadcrumb {
    color:blue;
    text-decoration: underline;
    cursor: pointer;
    padding-right: 5px;
}