.trmAdmin{
   width: 500px;
   position: absolute;
   top: 50%;
   left: 50%;
   transform:translate(-50%, -50%)
}
input[type="file"]::file-selector-button{
   padding:8px 15px;
   border:none;
   border-radius: 5px;
   background-color: #999;
   color: white;
}