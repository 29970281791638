$united-blue: #0C2340;
$united-purple: #6244BB;
$united-unselected: #D8D8D8;
$disabled-grey: #CCCCCC;
$black: #000000;
$white: #FFFFFF;
$charcoal: #333333;
$grey: #666666;
$light-border: #DDDDDD;
$failure: #D50032;
$warning-color: #ffc475;
$success-green : #208500;
$color-info: rgb(2, 134, 187);